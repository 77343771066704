/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [

  // PAGES
  {
    url: "/employees",
    name: "Medarbejdere",
    slug: "employees",
    icon: "UsersIcon",
  },
  {
    url: "/tasks",
    name: "Åbne opgaver",
    slug: "tasks",
    icon: "ListIcon",
  },
  {
    url: "/profiles",
    name: "Profiler",
    slug: "profiles",
    icon: "GridIcon",
  },
  {
    url: "/functions",
    name: "Funktions grupper",
    slug: "functions",
    icon: "CheckSquareIcon",
  },
  {
    url: "/hardwares",
    name: "Hardware",
    slug: "hardwares",
    icon: "CpuIcon",
  },
  {
    url: "/hardwareOptions",
    name: "Hardware Tilvalg",
    slug: "hardwareOptions",
    icon: "DatabaseIcon",
  },
];
