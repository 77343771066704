<template>
  <div class="the-navbar__user-meta flex items-center" v-if="userData !== null">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userData.userName }} ({{userData.userTitle}} : {{userData.userOrganisation}})</p>
      <!--<small>Available</small>-->
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img key="onlineImg" src="@/assets/images/profile-image.png" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

<!--
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>
-->

          <!-- <li @click="$router.push({ name: 'admin-home' }).catch(() => {})"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Admin</span>
          </li>

          <vs-divider class="m-1" />

          <li @click="$router.push({ name: 'tenant-home' }).catch(() => {})"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CloudIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Organization</span>
          </li>

          <vs-divider class="m-1" />      -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Log ud</span>
          </li>

        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
//import { msalMixin } from 'vue-msal';

export default {
  //mixins: [msalMixin],
  data() {
    return {

    }
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    }
  },
  methods: {
    logout() {
      this.$AuthService.logout()
    },
  }
}
</script>
